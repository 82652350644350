.App {
  text-align: center;
}

.index-logo {
  max-width: 100px;
  width: 100%;
  height: auto;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* step */

.icon-wrapper {
  background: #f7d794;
  padding: 0.6em;
  text-align: center;
  display: inline-flex;
  border-radius: 50%;
  width: 60%;
}

.wait {
  filter: grayscale(100%) opacity(30%);
}

.pass {
  filter: opacity(30%);
}

.check-wrapper {
  right: 0px;
  bottom: -4px;
  width: 60%;
  position: absolute;
  color: white;
}

.check-bg {
  background: #00a779;
  padding: 0.3em;
  text-align: center;
  display: inline-flex;
  border-radius: 50%;
  width: 40%;
}

.check-bg .anticon {
  font-size: 100%;
}

.label-step {
  margin: 10px 0;
}

.ant-row-step > .ant-col:not(:last-child):after {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  content: "";
  display: inline-block;
  height: 12px;
  right: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  width: 12px;
}

/* timeline */
.ant-timeline-item-last {
  padding-bottom: 0;
}

.with-padding {
  padding: 0 16px;
}

/* result */
.ant-result-title {
  line-height: 1.25em;
  margin-bottom: 20px;
}

.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.85);
}
